import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import  store  from './store/index.js';
import http from './api/http.js';
//引入封装Axios请求
// import Axios from './api/request11.js';

import 'vant/lib/index.css';

import {Toast,showDialog,showToast} from 'vant';
import vant from 'vant';

const app = createApp(App);
http.init(app);

import { hiPrintPlugin } from 'vue-plugin-hiprint'
// hiPrintPlugin.disAutoConnect() // 取消自动连接直接打印客户端，如果设置了取消自动连接，后续是获取不到打印机列表的

//使用全局变量，使用 getCurrentInstance 方法。
app.config.globalProperties.$http = http

//新的 provide/inject 功能可以穿透多层组件，实现数据从父组件传递到子组件。
app.provide('$http', http)

app.provide('$pluginName', hiPrintPlugin)

app.use(router).use(vant).use(Toast).use(http);
app.mount('#app');






