import axios from 'axios'
// import { Toast } from 'vant'
import { showToast, showLoadingToast, Notify } from 'vant';
import store from '@/store/index.js';
import { getCurrentInstance } from 'vue';

// import Vue from 'vue'
// npm install qs
import qs from 'qs'
axios.defaults.timeout = 50000;
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';


//'application/json;charset=utf-8';//
if (process.env.NODE_ENV == 'development') {
  axios.defaults.baseURL = '/';
}
else if (process.env.NODE_ENV == 'debug') {
  axios.defaults.baseURL = '/';
}
else if (process.env.NODE_ENV == 'production') {
  axios.defaults.baseURL = '/';
}
let ipAddress = axios.defaults.baseURL;
axios.interceptors.request.use((config) => {
  axios.defaults.headers[_Authorization] = store.getters.getToken();

  //console.log(axios.defaults.headers[_Authorization])
  _showLoading && loading.show(_showLoading);
  if (config.method === 'post') {
    //config.data =qs.stringify(config.data);
    //  console.log('post拦截：' + config.data);
  }
  return config;
}, (error) => {
  _showLoading && loading.close();
  return Promise.reject(error);
});


//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) => {
  _showLoading && loading.close();
  //对响应数据做些事
  if (res.data.success) {
    return res;
  }
  return Promise.resolve(res);
}, (error) => {
  _showLoading && loading.close();
  let httpMessage = '';
  if (error.response) {
    if (error.response.data && error.response.data.message) {
      httpMessage = error.response.data.message;
    } else if (error.response.status == '404') {
      httpMessage = "没有找到请求的地址";
    } else if (error.response.status == '401') {
      httpMessage = "未授权";
    }
  }
  else {
    httpMessage = '网络好像出了点问题~'
  }

  redirect(error.response || {}, httpMessage);
  return Promise.reject(error.response);
});

let $httpVue = null, currentToken = '';
const _Authorization = 'Authorization', _Bearer = 'Bearer ';
function init(vue) {
  $httpVue = vue;
}
let $loading;
let loading = {
  show(obj) {  //可选值为true,string="当前提示的文本"
    let text = '正在处理中.....';
    if (typeof obj == 'string') {
      text = obj;
    }
    // console.log(Loading);
    // showToast({
    //   duration: 0,
    //   forbidClick: true,
    //   message: text,
    // });
    console.log('show');
    // $loading && $loading.close();
    $loading = showLoadingToast({
      duration: 0,    //是否锁定
      message: _showLoading,   //显示在加载图标下方的加载文案
      forbidClick: true  //遮罩背景色
    });
    console.log($loading);
  },
  close() {
    if ($loading) {
      $loading.close();
    }
    console.log('close');
  }
}
function getToken() {
  if (currentToken) {
    return _Bearer + currentToken;
  } else {
    console.log(store);
    return store.getters.getToken();
  }
}
let _showLoading;
//_showLoading=true异步请求时会显示遮罩层,_showLoading=字符串，异步请求时遮罩层显示当前字符串
function post(url, params, showLoading) {

  console.log('post', url);
  _showLoading = showLoading;
  console.log("showLoading", showLoading);
  axios.defaults.headers[_Authorization] = getToken();
  // axios.headers.post['Content-Type'] = 'multipart/form-data';
  console.log(url);
  console.log(params);
  return new Promise((resolve, reject) => {
    //  axios.post(url, qs.stringify(params))   //
    console.log();
    axios.post(url, params)
      .then(response => {
        if (response.status == 202) {
          getNewToken(() => { post(url, params, _showLoading); });
          return;
        }
        // Toast.clear();
        resolve(response.data);
      }, err => {
        if (err.status == 202) {
          getNewToken(() => { post(url, params, _showLoading); });
          return;
        }
        reject(err.data && err.data.message ? err.data.message : '网络好像出了点问题~~');
      })
      .catch((error) => {
        //reject(error)
      })
  })
}

function postfile(url, params, showLoading) {

  console.log('postfile', url);
  _showLoading = showLoading;
  console.log("showLoading", showLoading);
  axios.defaults.headers[_Authorization] = getToken();
  console.log(url);
  console.log(params);
  return new Promise((resolve, reject) => {
    //  axios.post(url, qs.stringify(params))   //
    console.log();
    axios.post(url, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        if (response.status == 202) {
          getNewToken(() => { postfile(url, params, _showLoading); });
          return;
        }
        // Toast.clear();
        resolve(response.data);
      }, err => {
        if (err.status == 202) {
          getNewToken(() => { postfile(url, params, _showLoading); });
          return;
        }
        reject(err.data && err.data.message ? err.data.message : '网络好像出了点问题~~');
      })
      .catch((error) => {
        //reject(error)
      })
  })
}
//_showLoading=true异步请求时会显示遮罩层,_showLoading=字符串，异步请求时遮罩层显示当前字符串
function get(url, param, showLoading) {

  console.log('get', url);
  _showLoading = showLoading;
  axios.defaults.headers[_Authorization] = getToken();
  return new Promise((resolve, reject) => {
    axios.get(url, { params: param })
      .then(response => {
        if (response.status == 202) {
          getNewToken(() => { get(url, param, _showLoading); });
          return;
        }
        // Toast.clear();
        resolve(response.data)
      }, err => {
        // if (err.status == 202) {
        //   getNewToken(() => { get(url, param, _showLoading); });
        //   return;
        // }
        // Toast.clear();
        // redirect(response.data);
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

//_showLoading=true异步请求时会显示遮罩层,_showLoading=字符串，异步请求时遮罩层显示当前字符串
function put(url, param, showLoading) {
  console.log('put', url);
  _showLoading = showLoading;
  axios.defaults.headers[_Authorization] = getToken();
  return new Promise((resolve, reject) => {
    axios.put(url, param)
      .then(response => {
        if (response.status == 202) {
          getNewToken(() => { put(url, param, _showLoading); });
          return;
        }
        Toast.clear();
        resolve(response.data)
      }, err => {
        if (err.status == 202) {
          getNewToken(() => { put(url, param, _showLoading); });
          return;
        }
        redirect(response.data);
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

//_showLoading=true异步请求时会显示遮罩层,_showLoading=字符串，异步请求时遮罩层显示当前字符串
function del(url, param, showLoading) {
  console.log('delete');
  _showLoading = showLoading;
  axios.defaults.headers[_Authorization] = getToken();
  return new Promise((resolve, reject) => {
    // axios.delete(url, {
    //   params: param,
    //   data: param,
    //   paramsSerializer: params => {
    //     return qs.stringify(params, { indices: false })
    //   }
    // })
    axios(url, {
      method: 'delete',
      url: url,
      params: param,
      data: param,
    })
      .then(response => {
        if (response.status == 202) {
          getNewToken(() => { del(url, param, _showLoading); });
          return;
        }
        Toast.clear();
        resolve(response.data)
      }, err => {
        if (err.status == 202) {
          getNewToken(() => { del(url, param, _showLoading); });
          return;
        }
        redirect(response.data);
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

function createXHR() {
  if (XMLHttpRequest) {
    return new XMLHttpRequest();
  }
  if (ActiveXObject) {
    if (typeof arguments.callee.activeXString != "string") {
      var versions = [
        "MSXML2.XMLHttp.6.0",
        "MSXML2.XMLHttp",
        "MSXML2.XMLHttp.3.0"
      ];
      for (var i = 0; i < versions.length; i++) {
        try {
          new ActiveXObject(versions[i]);
          arguments.callee.activeXString = versions[i];
          break;
        } catch (e) {
          console.log("no");
        }
      }
    }
    return new ActiveXObject(arguments.callee.activeXString);
  }
}

function redirect(responseText, message) {
  try {
    let responseData = typeof responseText == 'string' ? JSON.parse(responseText) : responseText;
    showToast({ message: '~服务器好像出了点问题...', duration: 3000 });
    // $httpVue.$message.error(responseData.message || '~服务器好像出了点问题...')
    if ((responseData.hasOwnProperty('code') && responseData.code == 401)
      || (responseData.data && responseData.data.code == 401)) {

      console.log('currentToken');

      toLogin();
    } else {
      showToast({ message: '系统错误：' + message, duration: 3000 });
      // $httpVue.prototype.$message.error(message);
    }
  } catch (error) {
    console.log(error);
    $httpVue.prototype.$message.error(responseText)
  }
}
function toLogin() {
  currentToken = "";
  console.log('currentToken');
  console.log(currentToken);
  $httpVue.$router.push({ path: '/login', params: { r: Math.random() } });
}
//当前token快要过期时，用现有的token换成一个新的token
function getNewToken(callBack) {
  ajax({
    url: "/api/User/replaceToken",
    param: {},
    json: true,
    success: function (x) {
      if (x.status) {
        let userInfo = store.getters.getUserInfo();
        userInfo.token = x.data;
        currentToken = x.data;
        store.commit('setUserInfo', userInfo);
        callBack();
      } else {
        console.log(x.message);
        toLogin();
      }
    },
    errror: function (ex) {
      console.log(ex);
      toLogin();
    },
    type: "post",
    async: false
  });


}

function ajax(param) {
  console.log(param);
  let httpParam =
    Object.assign({
      url: '', headers: {},
      param: {}, json: true,
      success: function () { },
      errror: function () { },
      type: 'post', async: true
    }, param);

  httpParam.url = axios.defaults.baseURL + httpParam.url.replace(/\/?/, '');
  httpParam.headers[_Authorization] = getToken();
  var xhr = createXHR();
  // console.log(xhr.readyState);
  xhr.onreadystatechange = function () {
    if (xhr.status == 403 || xhr.status == 401) {
      redirect(xhr.responseText);
      return;
    }
    if (xhr.status == 202) {
      getNewToken(() => {
        ajax(param);
      });
      return;
    }
    if (xhr.readyState == 4 && xhr.status == 200) {
      httpParam.success(httpParam.json ? JSON.parse(xhr.responseText) : xhr.responseText);
      return;
    }
    if (xhr.status != 0 && xhr.readyState != 1) {
      httpParam.errror(xhr);
    }
  };
  //初始化请求
  xhr.open(
    httpParam.type,
    httpParam.url,
    httpParam.async
  );
  xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  for (const key in httpParam.headers) {
    xhr.setRequestHeader(key, httpParam.headers[key]);
  }
  let dataStr = '';
  for (const key in httpParam.param) {
    dataStr += key + "=" + httpParam.param[key];
  }
  try {
    xhr.send(dataStr);
  } catch (error) {
    toLogin();
    //  console.log(error)
  }
}

ajax.post = function (url, param, success, errror) {
  ajax({ url: url, param: param, success: success, error: errror, type: 'post' })
}
ajax.get = function (url, param, success, errror) {
  ajax({ url: url, param: param, success: success, error: errror, type: 'get' })
}




export default { post, get, put, del, postfile, ajax, init, ipAddress }
