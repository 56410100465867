import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';

import moment from 'moment';

// 任何一个name不能重复

///   http://ycf.ncsxyy.cn/redirect?state=ycf
///   http://ycf.ncsxyy.cn/redirect?state=old

const routes = [
  {
    path: '/404',
    name: '404',
    meta: { title: "404" },
    ico: "el-icon-user", funs: {
      a: false
    },
    component: () => import('@/view/redirect/404')
  }, {
    path: '/',
    name: 'home',
    meta: { title: "跳转页面" },
    ico: "el-icon-user", funs: {
      a: false
    },
    component: () => import('@/view/base')
  }, {
    path: '/auth',
    name: 'auth',
    meta: { title: "跳转页面" },
    ico: "el-icon-user", funs: {
      a: false
    },
    component: () => import('@/view/redirect/auth')
  }, {
    path: '/redirect',
    name: 'redirect',
    meta: { title: "跳转页面" },
    ico: "el-icon-user", funs: {
      a: false
    },
    component: () => import('@/view/redirect/redirect')
  }, {
    path: '/ycf',
    name: 'ycf',
    meta: { title: "孕心语", level: 100 },
    ico: "el-icon-user", funs: {
      a: false
    },
    component: () => import('@/view/ycf/ycf'),
    children: [{
      path: 'index',
      name: 'ycfindex',
      meta: { title: "主页" },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/ycf/index')
    }, {
      path: 'auth',
      name: 'ycfauth',
      meta: { title: "跳转页面" },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/ycf/auth')
    },
    {
      path: 'report1',
      name: 'ycfreport1',
      meta: { title: "报告" },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/ycf/report/report1')
    },
    {
      path: 'reportview',
      name: 'ycfreportview',
      meta: { title: "报告" },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/ycf/report/reportview')
    },
    {
      path: 'sign',
      name: 'ycfsign',
      meta: { title: "知情同意" },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/ycf/sign')
    },
    {
      path: 'testDo',
      name: 'ycftestDo',
      meta: { title: "测试" },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/ycf/testDo')
    }, {
      path: 'myScale',
      name: 'ycfmyScale',
      meta: { title: "我的列表" },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/ycf/myScale')
    }, {
      path: 'info',
      name: 'ycfinfo',
      meta: { title: "签约" },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/ycf/info')
    }, {
      path: 'myinfos',
      name: 'ycfmyinfos',
      meta: { title: "我的档案" },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/ycf/myinfos')
    }, {
      path: 'info2',
      name: 'ycfinfo2',
      meta: { title: "孕期选择" },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/ycf/info2')
    }, {
      path: 'kplist',
      name: 'ycfkplist',
      meta: { title: "科普文章" },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/ycf/kplist')
    }, {
      path: 'kpxx',
      name: 'ycfkpxx',
      meta: { title: "科普文章" },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/ycf/kpxx')
    }
    ]
  }, {
    path: '/old',
    name: 'old',
    meta: { title: "老年人生活能力评估", level: 100 },
    ico: "el-icon-user", funs: {
      a: false
    },
    component: () => import('@/view/old/old'),
    children: [{
      path: 'auth',
      name: 'oldauth',
      meta: { title: "老年人生活能力评估-跳转页面", level: 100 },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/old/auth')
    },
    {
      path: 'info',
      name: 'info',
      meta: { title: "签约", level: 100 },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/old/info')
    }, {
      path: 'myinfos',
      name: 'myinfos',
      meta: { title: "我的档案", level: 100 },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/old/myinfos')
    },
    {
      path: 'userlist',
      name: 'userlist',
      meta: { title: "info", level: 100 },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/old/userlist')
    }, {
      path: 'index',
      name: 'index',
      meta: { title: "主页", level: 100 },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/old/index')
    }, {
      path: 'myScale',
      name: 'myScale',
      meta: { title: "我的列表", level: 100 },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/old/myScale')
    }, {
      path: 'myScales',
      name: 'myScales',
      meta: { title: "我的列表", level: 100 },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/old/myScales')
    },
    {
      path: 'testDo',
      name: 'testDo',
      meta: { title: "测试", level: 100 },
      ico: "el-icon-user", funs: {
        a: false
      },
      component: () => import('@/view/old/testDo')
    },
    ]
  },
  {
    name: 'notFound',
    path: '/:path(.*)*',
    redirect: {
      name: 'redirect',
    },
  },
];
const whiteroutes = ['/Login', '/auth', '/ycf/auth', '/old/auth', '/redirect']
const testRoutes = ['/myScale']//需要建档


const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  var prefix = "";
  if (to.path.indexOf('ycf') > -1) {
    prefix = "孕心语-"
  } else {
    prefix = "老系统-"
  }
  if (title) {
    document.title = prefix + title;
  }

  console.log(from, to);
  let User = localStorage.user;

  let userJson = User ? JSON.parse(User) : null;

  if (whiteroutes.indexOf(to.path) > -1) {//白名单放过
    next();
  } else {
    console.log('User');
    console.log(User);
    if (!User || User === null || User === '' || to.path === '/') {
      //next({ path: '/redirect', query: { redirect: to.path } });
      var state = to.query.state;
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx89593e140fa7b55c&redirect_uri=http://hscj.ncsxyy.com/jump-oldman.html' +
        '&response_type=code&scope=snsapi_userinfo&state=' + state + '#wechat_redirect';
    } else if (userJson.user.level >= 100) {//高级权限放过
      next();
    } else if (to.path.indexOf('old') > -1) {//普通用户不允许访问
      //next('/ycf/index');
      next('/404');
    }
    else {
      next();
    }
  }
}
);

export { router };
